import React from "react"
import { createGlobalStyle } from "styled-components"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import PrintPage from "./PrintPage"

const GlobalStyle = createGlobalStyle`
@page 
    {
        size:  auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
    }
  body {
    font-family: 'Lato', sans-serif;
  }
`

function App () {
  return (
    <>
      <GlobalStyle/>
      <BrowserRouter>
        <Switch>
          <Route path={"/:customerId/:stayRef"} component={PrintPage}/>
          <Route component={() => <div>Not found</div>}/>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
