import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import sanityClient from "@sanity/client"
import { addHours, differenceInDays, differenceInYears, format, parseISO } from "date-fns"

const client = sanityClient({
  projectId: "rblicpth",
  dataset: "production",
  useCdn: false,
})

const PrintPage = ({match}) => {
  const observable = useRef(null)
  const [stay, setStay] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [diffDays, setDiffDays] = useState(null)

  useEffect(() => {
    const {customerId, stayRef} = match.params

    const handleData = data => {
      setCustomer(data)
      const s = data.stays.reduce((result, currentStay) => {
        if (currentStay._key === stayRef) {
          result = currentStay
        }
        return result
      }, null)
      setStay(s)

      if (s) {
        const checkin = addHours(parseISO(s.checkin), s.checkintime === "morning" ? 9 : 18)
        const checkout = addHours(parseISO(s.checkout), s.checkouttime === "morning" ? 9 : 18)
        setDiffDays(differenceInDays(checkout, checkin))
      }
      console.log(s, data)
    }

    observable.current = client.observable
      .fetch(`*[_id == $id && _type == $type ]{animals[]->{...}, stays[]{animals[]->{breed->{name}, vet->{name}, "imageUrl": image.asset->url , ...}, ...}, ...} [0]`
        , {type: "customer", id: customerId})
      .subscribe(handleData)

    return () => {
      observable.current.unsubscribe()
    }
  }, [match.params])

  if (!stay || !customer) {
    return <Loading>
      <LoadingText>Laster inn...</LoadingText>
    </Loading>
  }
  return (
    <Page>
      <Header>
        <Jumbo gender={"male"}>

          {stay.animals && stay.animals.map(animal => (
            <JumboText key={animal._id} gender={animal.gender}>
              {animal.name}
            </JumboText>
          ))}

        </Jumbo>
        <Row>{stay.animals.map(a => (
          <>{a.imageUrl && <Image src={a.imageUrl} alt={"image of " + a.name}/>}</>
        ))}</Row>
      </Header>
      <Main>
        <Age>
          <Label>alder</Label>
          <Value>{stay.animals.map(animal => {
            if (!animal.birth) {
              return ""
            }

            return `${differenceInYears(new window.Date(), parseISO(animal.birth))} år`
          }).join(", ")}</Value>
        </Age>
        <Breed>
          <Label>rase</Label>
          <Value>{stay.animals.map(animal => animal.breed ? animal.breed.name : "").join(", ")}</Value>
        </Breed>
        <Feed>
          <Label>fòr</Label> <Value>{stay.feed || ""}</Value>
        </Feed>
        <Medicin>
          <Label>medisin</Label> <Value>{(stay.medicin || []).join(", ")}</Value>
        </Medicin>
        <Vaccine>
          <Label>siste vaksine</Label>
          <Value>{stay.animals.map(animal => animal.vaccine || "").join(", ")}</Value>
        </Vaccine>
        <Vet>
          <Label>veterinær</Label> <Value>{stay.vet || ""}</Value>
        </Vet>
        <Owner>
          <Value>{customer.name}</Value>
          <div>{customer.address}</div>
          <div>{customer.postalCode}</div>
          <Value>{[customer.mob, customer.phone].filter(p => p).join(", ")}</Value>
        </Owner>
        <Notes>
          <Label>merknader</Label>
          <Value>{[...(stay.notes || []), ...stay.animals.map(a => a.notes || [])].join(", ")}</Value>
        </Notes>
      </Main>
      <Footer>
        <Cell>
          <Label>Sjekker inn</Label>
          <Label>{stay.checkintime}</Label>
          <Date>{format(parseISO(stay.checkin), "dd.MM.yyyy")}</Date>
        </Cell>
        <Cell>
          <Label>
            Varighet
          </Label>
          <Label>
            dager
          </Label>
          <Date>{diffDays}</Date>
        </Cell>
        <Cell>
          <Label>Sjekker ut</Label>
          <Label>{stay.checkouttime}</Label>
          <Date>{format(parseISO(stay.checkout), "dd.MM.yyyy")}</Date>
        </Cell>
      </Footer>
    </Page>
  )
}

const Page = styled.div`
  display: grid;
  grid-template-areas:
  ". header header header . "
  ". main main main ."
  "footer footer footer footer footer";
  height: 97vh;
  grid-template-rows: .5fr 1fr 1fr;
  grid-template-columns: auto;
`

const Header = styled.div`
  grid-area: header;
`

const Jumbo = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

const JumboText = styled.h1`
color: ${p => p.gender === "female" ? "#b45c97" : "#2f84c2"};
font-size: 25vw;
margin: 12px 48px;
font-family: 'oswald', sans-serif;
`

const Main = styled.div`
  grid-area: main;
  display: grid;
  grid-template-areas:
  "age breed feed"
  "medicin vaccine vet"
  "owner owner notes"
  "owner owner notes";
  grid-template-columns: 1fr 1fr 1fr;
`

const Image = styled.img`
height: 200px;
width: 200px;
object-fit: cover;
border-radius: 100px;
margin: 6px 24px;
`

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 5px rgba(0,0,0,.2);
  padding: 12px 12px;
  text-align: center;
`

const Age = styled(Cell)`
grid-area: age;
`

const Breed = styled(Cell)`
grid-area: breed;
`

const Feed = styled(Cell)`
grid-area: feed;
`
const Medicin = styled(Cell)`
grid-area: medicin;
`
const Vaccine = styled(Cell)`
grid-area: vaccine;
`
const Vet = styled(Cell)`
grid-area: vet;
`

const Owner = styled(Cell)`
grid-area: owner;
`

const Notes = styled(Cell)`
grid-area: notes;
`

const Label = styled.div`
  font-weight: 300;
  font-size: 16px;

`

const Value = styled.div`
  font-weight: 600;
  font-size: 36px;
`

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px;
  padding: 0 48px;
`

const Row = styled.div`
display: flex;
justify-content: center;
flex-direction: row;
align-items: center;
`

const Date = styled.div`
  font-size: 36px;
  font-family: 'Oswald', sans-serif;
  margin: 24px 24px;
  text-align: center;
`

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoadingText = styled.div`
  font-weight: 600;
  font-size: 96px;
  font-family: 'Oswald', sans-serif;
`

export default PrintPage
